// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-book-list-jsx": () => import("./../../../src/pages/bookList.jsx" /* webpackChunkName: "component---src-pages-book-list-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insta-jsx": () => import("./../../../src/pages/insta.jsx" /* webpackChunkName: "component---src-pages-insta-jsx" */),
  "component---src-pages-mappingourway-jsx": () => import("./../../../src/pages/mappingourway.jsx" /* webpackChunkName: "component---src-pages-mappingourway-jsx" */),
  "component---src-pages-maps-jsx": () => import("./../../../src/pages/maps.jsx" /* webpackChunkName: "component---src-pages-maps-jsx" */)
}

